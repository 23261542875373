import React, {useEffect, useMemo, useState} from 'react';

import classNames from 'classnames';

import {AttributesGroup, ReducedAttribute} from '../OptionsTable/types';

import {
  BaseQuestionnaireButtons,
  BaseQuestionnaireImage,
  BaseQuestionnaireProgress,
  BaseQuestionnaireQuestion,
  BaseQuestionnaireSkeleton,
} from './components';

import './BaseQuestionnaire.scss';

interface BaseQuestionnaireProps {
  onDone: () => void;
  isLoading?: boolean;
  initialQuestionId?: number | null;
  onCancel?: () => void;
  error?: string;
  questionnaireState: Record<number, {code: string}>;
  isInitialLoading?: boolean;
  onOptionChange: (param: ReducedAttribute, optionCode: string) => void;
  optionParams: AttributesGroup[];
  headerText: string;
  attrControl: Record<string, string>;
  finishButtonText: string;
  isFinishDisabled?: boolean;
}

const BaseQuestionnaire = (props: BaseQuestionnaireProps) => {
  const {
    error,
    onOptionChange,
    onDone,
    isLoading,
    initialQuestionId,
    onCancel,
    questionnaireState,
    isInitialLoading,
    optionParams,
    headerText,
    attrControl,
    finishButtonText,
    isFinishDisabled,
  } = props;

  const [selectedParamIndex, setSelectedParamIndex] = useState(0);

  const selectedParam = useMemo(() => {
    return optionParams[selectedParamIndex];
  }, [optionParams, selectedParamIndex]);

  const selectedOptionCodes: Record<number, string> = useMemo(() => {
    const codes: Record<number, string> = {};
    selectedParam?.attrs.forEach((attr) => {
      const code = questionnaireState[attr.id]?.code;
      if (code) codes[attr.id] = code;
    });
    return codes;
  }, [questionnaireState, selectedParam]);

  const shouldDisplayImages = useMemo(() => {
    return optionParams.some((group) => group.attrs.some((attr) => attr.image));
  }, [optionParams]);

  const containerClasses = useMemo(() => {
    return classNames('base-questionnaire', {
      'base-questionnaire_no-image': !shouldDisplayImages,
    });
  }, [shouldDisplayImages]);

  const findInitialQuestionIndex = (optionParams: AttributesGroup[], initialQuestionId: number) => {
    for (let i = 0; i < optionParams.length; i++) {
      const attrIndex = optionParams[i].attrs.findIndex((attr) => attr.id === initialQuestionId);
      if (attrIndex !== -1) return i;
    }
    return -1;
  };

  useEffect(
    function onLoaded() {
      if (!isInitialLoading && initialQuestionId) {
        const index = findInitialQuestionIndex(optionParams, initialQuestionId);
        if (index !== -1) {
          setSelectedParamIndex(index);
        }
      }
    },
    [isInitialLoading, initialQuestionId, optionParams],
  );

  if (isInitialLoading) {
    return <BaseQuestionnaireSkeleton />;
  }

  return (
    <div className={containerClasses} data-testid="base-questionnaire">
      {shouldDisplayImages && (
        <BaseQuestionnaireImage key={selectedParam?.attrs[0].image} url={selectedParam?.attrs[0].image} />
      )}
      <div className="base-questionnaire__header">
        <h4>{headerText}</h4>
      </div>
      <BaseQuestionnaireQuestion
        attrControl={attrControl}
        error={error}
        handleChangeOption={onOptionChange}
        isLoading={isLoading}
        selectedOptionCodes={selectedOptionCodes}
        selectedParam={selectedParam}
      />
      <div className="base-questionnaire__footer">
        <BaseQuestionnaireProgress currentQuestion={selectedParamIndex + 1} totalQuestions={optionParams.length} />
        <BaseQuestionnaireButtons
          finishButtonText={finishButtonText}
          isFinishDisabled={isFinishDisabled}
          isIndividual={Boolean(initialQuestionId)}
          isLastParam={selectedParamIndex === optionParams.length - 1}
          isLoading={isLoading}
          onCancel={onCancel}
          onDone={onDone}
          selectedParamIndex={selectedParamIndex}
          setSelectedParamIndex={setSelectedParamIndex}
        />
      </div>
    </div>
  );
};

export default BaseQuestionnaire;
