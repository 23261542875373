import React from 'react';

import {ReducedAttribute, Value} from '../types';

import Option from './Option';
import RadioGroup from 'components/atoms/RadioGroup';

interface RadioOptionsProps {
  selectedParam?: ReducedAttribute;
  selectedOptionCode?: string;
  onChangeOption: (id: number, value: string) => void;
}

const RadioOptions = ({selectedParam, selectedOptionCode, onChangeOption}: RadioOptionsProps) => {
  if (!selectedParam?.options) {
    return null;
  }

  const definedOptions = selectedParam.options.filter(Boolean) as Value[];
  const optionsWithImages = definedOptions.filter((option) => option.image);
  const optionsWithoutImages = definedOptions.filter((option) => !option.image);

  return (
    <RadioGroup
      name="option"
      onChange={(value) => onChangeOption(selectedParam.id, value)}
      selectedValue={selectedOptionCode}
    >
      {optionsWithImages.length > 0 && (
        <ul className="grid-wrapper">
          {optionsWithImages.map((option) => (
            <Option key={`${selectedParam.id}-${option.code}`} {...option} />
          ))}
        </ul>
      )}
      {optionsWithoutImages.length > 0 && (
        <ul className="plain-column-wrapper">
          {optionsWithoutImages.map((option) => (
            <Option key={`${selectedParam.id}-${option.code}`} {...option} />
          ))}
        </ul>
      )}
    </RadioGroup>
  );
};

export default RadioOptions;
