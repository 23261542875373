import React, {useRef, useEffect} from 'react';

import {ReactSVG} from 'react-svg';

import iconArrow from '../../../assets/icon-arrow-left.svg';
import DropdownList from '../DropdownList';

import {useListOptions, useCssClasses, useDropdownSelector, useDropdownClickOutside} from './hooks';
import {AttributeSelectorProps} from './types';

import './AttributeSelector.scss';

const AttributeSelector = (props: AttributeSelectorProps) => {
  const {onChange, options, value} = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const {
    searchText,
    isDropdownShown,
    filteredOptions,
    setIsDropdownShown,
    handleOptionChange,
    handleInputChange,
    handleDropdownToggle,
    handleInputBlur,
  } = useDropdownSelector(value, onChange, options);

  useDropdownClickOutside({dropdownRef, isDropdownShown, setIsDropdownShown});

  const list = useListOptions({options: filteredOptions, setIsDropdownShown, onChange: handleOptionChange});

  const {selectClasses, dropDownWrapperClasses} = useCssClasses(isDropdownShown);

  useEffect(() => {
    if (isDropdownShown && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isDropdownShown]);

  return (
    <div className={`attribute-select ${isDropdownShown ? 'is-open' : ''}`} ref={dropdownRef}>
      <div className={`attribute-select--wrapper ${selectClasses}`} onClick={handleDropdownToggle}>
        <div className="attribute-select--content">
          {!isDropdownShown ? (
            value.label
          ) : (
            <input
              onBlur={handleInputBlur}
              onChange={handleInputChange}
              onClick={(e) => {
                e.stopPropagation();
                inputRef.current?.select();
              }}
              ref={inputRef}
              type="text"
              value={searchText}
            />
          )}
        </div>
        <div className="attribute-select--right-side">
          <ReactSVG className="attribute-select--arrow" src={iconArrow} />
        </div>
      </div>
      <div className={`attribute-select--dropdown ${dropDownWrapperClasses}`}>
        <DropdownList className="card shadow__z2" list={list} />
      </div>
    </div>
  );
};

export default AttributeSelector;
