import React, {Fragment, useMemo} from 'react';

import {ReactSVG} from 'react-svg';
import classNames from 'classnames';

import helpIcon from '../../../assets/icon-question-mark.svg';
import Tooltip from '../Tooltip';

import './AttributeHelpIcon.scss';

interface AttributeHelpIconProps {
  helpLink?: string;
  helpText?: string;
  isActive?: boolean;
}
const AttributeHelpIcon = (props: AttributeHelpIconProps) => {
  const {helpLink, helpText, isActive} = props;

  const classes = useMemo(() => classNames('attribute-help-icon spacing left__is-5', {active: isActive}), [isActive]);

  const helpNode = useMemo(() => {
    if (helpText || helpLink) {
      return (
        <div className="attribute-help-tooltip">
          {helpText}
          {helpLink && <a href={helpLink}> Help&nbsp;center </a>}
        </div>
      );
    }
  }, [helpText, helpLink]);

  if (helpNode) {
    return (
      <Tooltip msg={helpNode} position="top">
        <ReactSVG className={classes} src={helpIcon} />
      </Tooltip>
    );
  }
  return <Fragment />;
};

export default AttributeHelpIcon;
