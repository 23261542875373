import React from 'react';

import ProgressBar from '../../ProgressBar';

interface BaseQuestionnaireProgressProps {
  currentQuestion: number;
  totalQuestions: number;
}

const BaseQuestionnaireProgress = ({currentQuestion, totalQuestions}: BaseQuestionnaireProgressProps) => {
  return (
    <div className="base-questionnaire__progress-container">
      <p className="label uppercase">
        {currentQuestion} out of {totalQuestions}
      </p>
      <ProgressBar currentStep={currentQuestion} totalSteps={totalQuestions} />
    </div>
  );
};

export default BaseQuestionnaireProgress;
